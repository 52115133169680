import styles from './InfoRow.module.css';

type Props = {
  children: React.ReactNode;
  className: string;
  iconClassName: string;
};

function InfoRow({ children, className, iconClassName }: Props) {
  return (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.iconContainer}>
        <i className={iconClassName} />
      </div>
      <div className={styles.contentContainer}>{children}</div>
    </div>
  );
}

InfoRow.defaultProps = {
  className: '',
  iconClassName: '',
};

export default InfoRow;
