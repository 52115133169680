import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';


import useDateTimeFormatting from 'src/app/hooks/useDateTimeFormatting';

import { Activity } from 'src/app/models/entity/Activity';

import useActivityDateTimeSummaryFormatter from 'src/app/hooks/useActivityDateTimeSummaryFormatter';

import { translateActivityType } from 'src/app/utils/translation-utils';

import InfoRow from 'src/app/components/views/InfoRow/InfoRow';
import StackedView from 'src/app/components/views/StackedView/StackedView';
import { TagContainer, TagTextView } from 'src/app/components/views/TagView';

import ActivitySessionList from './ActivitySessionList';
import AttachmentSection from './AttachmentSection';
import SectionHeader from './SectionHeader';
import ServantSection from './ServantSection';
import OrganizerSection from './OrganizerSection';
import VenueSection from './VenueSection';
import EnquirySection from './EnquirySection';


type Props = {
  activity: Activity;
}

const ActivityDetail = ({ activity }: Props) => {

  const { t, i18n } = useTranslation();

  const styles = useMemo(() => makeStyles(), []);

  const translatedActivityType = useMemo(
    () => t(translateActivityType(activity.type)),
    [i18n.language, activity.type]
  );

  const {
    resolvedSessionCount,
  } = useActivityDateTimeSummaryFormatter(activity);

  const formattedUpdatedAt = useDateTimeFormatting(activity.updatedAt);

  return (
    <div className="flex-fill" style={styles.container}>
      <StackedView>
        <div style={styles.header}>
          <h5>
            {/* Activity name */}
            {activity.name}
          </h5>
        </div>
      </StackedView>

      {/* Meta info / tags */}
      <StackedView>
        <TagContainer>
          {/* Online? */}
          {activity.isOnline && (
            <TagTextView text={t('activityParticipationMode.online')} />
          )}

          {/* Onsite? */}
          {activity.isOnsite && (
            <TagTextView text={t('activityParticipationMode.onsite')} />
          )}

          {/* Activity type */}
          {translatedActivityType && (
            <TagTextView text={translatedActivityType} />
          )}

          {/* Number of sessions */}
          {resolvedSessionCount > 0 && (
            <TagTextView
              text={t('activityList.sessionCount', {
                count: resolvedSessionCount,
              })}
            />
          )}
        </TagContainer>
      </StackedView>

      {/* Description */}
      {activity.description?.length > 0 && (
        <StackedView>
          <div className="text-body border p-1 app-line-breakable">
            {activity.description}
          </div>
        </StackedView>
      )}

      {/* Date and time */}
      <ActivitySessionList activity={activity} />

      {/* Major venue */}
      <VenueSection activity={activity} />

      {/* Servants */}
      <ServantSection activity={activity} />

      {/* Scripture */}
      {activity.scripture?.length > 0 && (
        <StackedView>
          <SectionHeader text={t('activity.scripture.label')} />
          <div className="text-body app-line-breakable">
            {activity.scripture}
          </div>
        </StackedView>
      )}

      {/* Target Audience */}
      {activity.targetAudience?.length > 0 && (
        <StackedView>
          <SectionHeader text={t('activity.targetAudience.label')} />
          <div className="text-body app-line-breakable">
            {activity.targetAudience}
          </div>
        </StackedView>
      )}

      {/* Remarks */}
      {activity.remarks?.length > 0 && (
        <StackedView>
          <SectionHeader text={t('activity.remarks.label')} />
          <div className="text-body app-line-breakable">{activity.remarks}</div>
        </StackedView>
      )}

      {/* Enquiries */}
      <EnquirySection activity={activity} />

      {/* Attachments */}
      <AttachmentSection activity={activity} />

      {/* Host Organizer / Coorganizers */}
      <OrganizerSection activity={activity} />

      <StackedView />

      <hr />

      <InfoRow iconClassName="fas fa-history text-muted">
        <div className="text-muted">
          {t('activityRemarks.updateAt', {
            updatedAt: formattedUpdatedAt,
          })}
        </div>
      </InfoRow>

      {activity.delegateAssociation?.name?.length > 0 && (
        <InfoRow className="mt-5" iconClassName="fas fa-exclamation text-body">
          <div className="text-body">
            {t('activityRemarks.delegation', {
              delegate: activity.delegateAssociation.name,
            })}
          </div>
        </InfoRow>
      )}
    </div>
  );
}


const makeStyles = () => ({
  // Layouts
  container: {
    padding: '.5rem',
    paddingBottom: 30,
    overflow: 'auto',
  },
  header: {
    flex: 1,
    marginRight: 3,
  },
});

export default ActivityDetail;
