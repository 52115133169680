import { OrganizationVenue } from 'src/app/models/entity/OrganizationVenue';

import InfoRow from 'src/app/components/views/InfoRow/InfoRow';


type Props = {
  venue: OrganizationVenue;
};

const VenueRow = ({ venue }: Props) => {

  return (
    <InfoRow iconClassName="fas fa-map-marker-alt text-body">
      <div className="text-body">
        <div>{venue.name}</div>

        {venue.address?.length > 0 && (
          <div className="text-muted">{venue.address}</div>
        )}

        {venue.arrivalInstruction?.length > 0 && (
          <div className="text-muted">{venue.arrivalInstruction}</div>
        )}
      </div>
    </InfoRow>
  );
}


export default VenueRow;
