import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';

import { AxiosError, AxiosResponse } from 'axios';


import {
  selectActivity,
} from 'src/app/redux/features/activity/activitiesSlice';

import { Activity } from 'src/app/models/entity/Activity';

import logEvent from 'src/app/utils/logger-utils';

import { usePermalinkResolver } from 'src/app/hooks/usePermalinkResolver';

import { showPermalinkable } from 'src/app/utils/activity-api-utils';


import ActivityDetail from './views/ActivityDetail';
import ActivityFooter from './views/ActivityFooter';


type ScreenProps = {
};

const Permalinkable = ({}: ScreenProps) => {

  const { t } = useTranslation();

  const { permalinkScope } = useParams<'permalinkScope'>();
  const { permalinkAlias } = useParams<'permalinkAlias'>();

  // try to select the permalinkable data from redux state
  const activityFromState = useSelector(
    selectActivity(permalinkScope || '', permalinkAlias || '')
  );

  const { resolvedScope, resolvedAlias } = usePermalinkResolver(permalinkScope || '', permalinkAlias || '');


  const [isRefreshing, setRefreshing] = useState(false);

  const [activity, setActivity] = useState<Activity | undefined>();

  const [hasApiError, setHasApiError] = useState(false);
  const [badAxiosResponse, setBadAxiosResponse] = useState<AxiosResponse | undefined>(undefined);

  const [isUnsupportedResource, setUnsupportedResource] = useState(false);

  useEffect(() => {
    if (!resolvedAlias) {
      setActivity(undefined);
      setUnsupportedResource(true);
      return;
    }

    console.log(
      `PermalinkableScreen captured new (resolved) params: [${permalinkScope}] [${permalinkAlias}] >>> @${resolvedScope}/${resolvedAlias}`
    );

    // try to take data from redux state
    setActivity(activityFromState);

    refreshPermalinkable();
  }, [resolvedScope, resolvedAlias]);

  const refreshPermalinkable = () => {
    if (!resolvedAlias) {
      console.log('Alias is not ready. Perhaps, still resolving, or unable to resolve an alias...');
      return;
    }

    if (isRefreshing) {
      console.log('Still refreshing. Not to fire another refresh request.');
      return;
    }

    console.log(`Going to fetch permalinkable with resolved @${resolvedScope}/${resolvedAlias}`);

    setRefreshing(true);
    setHasApiError(false);
    setBadAxiosResponse(undefined);
    setUnsupportedResource(false);

    // TODO Respect `resolvedScope`
    showPermalinkable(resolvedAlias, /* TODO */ 'zh')
      .then(permalinkable => {

        // Note that the permalinkable object may or may not be an activity
        setActivity(permalinkable.activity);

        // TODO Handle more resource type in the future.

        if (permalinkable.activity) {
          logEvent('permalinkable_view', {
            permalinkAlias: permalinkable.activity.permalink?.alias,
            permalinkType: 'activity',
            title: permalinkable.activity.name,
            url: permalinkable.activity.permalink?.shareUrl,
          });
        } else {
          setUnsupportedResource(true);
        }
      })
      .catch((e: AxiosError) => {
        setHasApiError(true);
        setBadAxiosResponse(e.response);
      })
      .finally(() => {
        setRefreshing(false);
      });
  };

  return (
    <div className="d-flex flex-column bg-white">
      {isRefreshing && <p>載入中⋯⋯</p>}
      {!!activity && (
        <>
          <Helmet>
            <title>
              {activity.name} | {t('siteTitle')}
            </title>
            <meta
              property="og:title"
              id="og-title"
              content={`${activity.name} | ${t('siteTitle')}`}
            />
            <meta
              name="description"
              property="og:description"
              id="og-description"
              content={activity.description}
            />
          </Helmet>

          <ActivityDetail activity={activity} />
          <ActivityFooter activity={activity} />
        </>
      )}
    </div>
  );
};

export default Permalinkable;
