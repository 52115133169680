import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';


import { getAnalytics, logEvent } from 'firebase/analytics';
import firebase from 'src/firebase';

import Home from './app/components/pages/Home/Home';
import Permalinkable from './app/components/pages/Permalinkable/Permalinkable';
import PrivacyStatement from './app/components/pages/PrivacyStatement/PrivacyStatement';

import NavBar from './app/components/views/Site/NavBar';
import Footer from './app/components/views/Site/Footer';

import './App2.css';

function App() {
  const location = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    // Log all page views whenever page location changes
    const analytics = getAnalytics(firebase);
    logEvent(analytics, 'page_view');
  }, [location]);

  return (
    <div className="container-fluid px-0">
      <Helmet>
        <title>{t('siteTitle')}</title>
        <meta property="og:title" id="og-title" content={t('siteTitle')} />
        <meta
          name="description"
          property="og:description"
          id="og-description"
          content={t('appCaption')}
        />
        <meta name="keywords" content={t('siteKeyword')} />
      </Helmet>

      <NavBar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="at/:permalinkScope"
          element={
            <div className="container px-0" style={{ position: 'relative' }}>
              <Permalinkable />
            </div>
          }
        />
        <Route
          path="at/:permalinkScope/:permalinkAlias"
          element={
            <div className="container px-0" style={{ position: 'relative' }}>
              <Permalinkable />
            </div>
          }
        />
        <Route path="privacy" element={<PrivacyStatement />} />
        {/* <Route path="*" element={<Navigate to="/home" />} /> */}
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
