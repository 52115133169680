import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

import zh_HK from 'src/assets/i18n/zh_HK.json';


const resources: Resource = {
  zh_HK: {
    translation: zh_HK,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'zh_HK',
    fallbackLng: 'zh_HK',
  });

export default i18n;
