import { useTranslation } from 'react-i18next';

// import { Fab } from '@material-ui/core';


import { Activity } from 'src/app/models/entity/Activity';

import logEvent from 'src/app/utils/logger-utils';
import { ActivityEventName } from 'src/app/config/EventName';

import FabLink from 'src/app/components/views/FabLink/FabLink';


type Props = {
  activity: Activity;
}

/**
 * Float action button for activity
 */
const ActivityNavigateToHostButton = ({ activity }: Props) => {

  const { t } = useTranslation();

  // const redirectToActivity = () => {
  //   window.open(activity.externalPrimaryUrl);
  // };

  const handleClick = () => {
    if (activity.externalPrimaryUrl?.length) {
      logEvent(ActivityEventName.navigateToActivityHost, {
        permalinkAlias: activity.permalink?.alias,
        title: activity.name,
        url: activity.externalPrimaryUrl,
      });
    }
  };

  return !!activity.externalPrimaryUrl ? (
    /* <Fab color="secondary" variant="extended" onClick={redirectToActivity}>
        {activity.hasExternalApplication
        ? t('activity.navigateToHost.withExtApp')
        : t('activity.navigateToHost.withoutExtApp')}
      </Fab> */

    <FabLink
      target="_blank"
      to={activity.externalPrimaryUrl}
      variant="extended"
      onClick={handleClick}
    >
      {activity.hasExternalApplication
        ? t('activity.navigateToHost.withExtApp')
        : t('activity.navigateToHost.withoutExtApp')}
      <i className="fas fa-sign-in-alt mx-1 fs-5" />
    </FabLink>
  ) : null;
}



export default ActivityNavigateToHostButton;
