import React, {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';


import {Activity, ActivityEnquiry} from 'src/app/models/entity/Activity';
import {EnquiryTypeCode} from 'src/app/models/entity/definition/EnquiryType';

import EmailTouchable from 'src/app/components/views/Contact/EmailTouchable';
import FaxTouchable from 'src/app/components/views/Contact/FaxTouchable';
import PlainTextUntouchable from 'src/app/components/views/Contact/PlainTextUntouchable';
import StackedView from 'src/app/components/views/StackedView/StackedView';
import TelTouchable from 'src/app/components/views/Contact/TelTouchable';
import WhatsappTouchable from 'src/app/components/views/Contact/WhatsappTouchable';

import SectionHeader from './SectionHeader';

type Props = {
  activity: Activity;
};

const EnquirySection = ({activity}: Props) => {
  const {t} = useTranslation();

  const [enquiries, setEnquiries] = useState<ActivityEnquiry[]>([]);

  const [enquiryMessage, setEnquiryMessage] = useState('');

  useEffect(() => {
    setEnquiries(activity.enquiries || []);
  }, [activity.enquiries]);

  useEffect(() => {
    setEnquiryMessage(
      t('activity.enquiry.enquiryMessage', {
        interpolation: {
          escapeValue: false,
        },
        title: activity.name,
        url: activity.permalink.shareUrl,
      }),
    );
  }, [activity.name, activity.permalink.shareUrl]);

  return enquiries?.length > 0 ? (
    <StackedView verticalSpacingSize="Large">
      <SectionHeader text={t('activity.enquiry.label')} />

      {enquiries.map((enquiry, index) => (
        <Fragment key={index + '.' + enquiry.type + '.' + enquiry.value}>
          {enquiry.type === EnquiryTypeCode.email ? (
            <EmailTouchable
              email={enquiry.value}
              title={enquiry.title}
              partyName={enquiry.title || t('coorganizerType.organizer.label')}
            />
          ) : enquiry.type === EnquiryTypeCode.fax ? (
            <FaxTouchable
              faxNumber={enquiry.value}
              title={enquiry.title}
              partyName={enquiry.title || t('coorganizerType.organizer.label')}
            />
          ) : enquiry.type === EnquiryTypeCode.tel ? (
            <TelTouchable
              telNumber={enquiry.value}
              title={enquiry.title}
              partyName={enquiry.title || t('coorganizerType.organizer.label')}
            />
          ) : enquiry.type === EnquiryTypeCode.whatsapp ? (
            <WhatsappTouchable
              whatsappNumber={enquiry.value}
              title={enquiry.title}
              partyName={enquiry.title || t('coorganizerType.organizer.label')}
              enquiryMessage={enquiryMessage}
            />
          ) : (
            // Unsupported enquiry type?
            <PlainTextUntouchable
              content={enquiry.value}
              title={enquiry.title}
            />
          )}
        </Fragment>
      ))}
    </StackedView>
  ) : null;
};

export default EnquirySection;
