import React, { HTMLAttributeAnchorTarget, useMemo } from 'react';
import { Link } from 'react-router-dom';

import styles from './FabLink.module.css';

type Props = {
  children: React.ReactNode;
  target: HTMLAttributeAnchorTarget;
  to: string;
  variant?: string;
  onClick?: () => void;
};

/**
 * Link as a Float action button
 */
const FabLink = ({ children, target, to, variant, onClick }: Props) => {
  const isExternal = useMemo(() => /^https?:/.test(to), [to]);

  return isExternal ? (
    <a
      className={`${styles.link} ${
        variant === 'extended' ? styles.extended : ''
      }`}
      href={to}
      rel="nofollow noopener noreferrer"
      target={target}
      onClick={onClick}
    >
      {children}
    </a>
  ) : (
    <Link
      className={`${styles.link} ${
        variant === 'extended' ? styles.extended : ''
      }`}
      target={target}
      to={to}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

export default FabLink;
