export enum ActivityEventName {
  expandSessionList = 'activity_expandSessionList',
  collapseSessionList = 'activity_collapseSessionList',

  openMapToVenue = 'activity_openMapToVenue',

  navigateToActivityHost = 'activity_navigateToHost',

  navigateToIndividual = 'activity_navigateToIndividual',

  navigateToOrganization = 'activity_navigateToOrganization',

  shareActivity = 'activity_share',
}
