function AppFeature() {
  return (
    <div className="mt-5 fs-5">
      <h2>特色</h2>
      <ul>
        <li>跨平台，支援 Android 和 iPhone （iPhone 版本即將推出）</li>
        <li>涵蓋講座、課程，查經團契等各類型聚會</li>
        <li>囊括實體、網上舉辦的聚會</li>
        <li>依個人需要篩選、搜尋聚會</li>
        <li>更多功能將陸續推出⋯⋯</li>
      </ul>
    </div>
  );
}

export default AppFeature;
