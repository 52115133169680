import { useEffect, useState } from 'react';

import { DateTime } from 'luxon';


import { Activity } from 'src/app/models/entity/Activity';

import logEvent from 'src/app/utils/logger-utils';
import { ActivityEventName } from 'src/app/config/EventName';

import useActivityDateTimeSummaryFormatter from 'src/app/hooks/useActivityDateTimeSummaryFormatter';


import InfoRow from 'src/app/components/views/InfoRow/InfoRow';
import StackedView from 'src/app/components/views/StackedView/StackedView';
import { TagTextView } from 'src/app/components/views/TagView';

import ActivitySessionRow from './ActivitySessionRow';


type Props = {
  activity: Activity;
}

const ActivitySessionList = ({ activity }: Props) => {

  const {
    dateSummary,
    timeSummaryEmoji,
    timeSummary,
    nextAppointedAtWeekday,
  } = useActivityDateTimeSummaryFormatter(activity);

  const [isSessionListAvailable, setSessionListAvailable] = useState(false);

  const [isExpanded, setExpanded] = useState(false);

  const [upcomingSessionIdx, setUpcomingSessionIdx] = useState(-1);

  useEffect(() => {
    setSessionListAvailable(activity.sessions?.length > 0);

    const now = DateTime.now();

    setUpcomingSessionIdx((activity.sessions || []).findIndex(session => session.startedAt && DateTime.fromISO(session.startedAt) > now));
  }, [activity.sessions]);

  const toggleExpanded = () => {
    setExpanded((isExpanded) => {
      logEvent(
        isExpanded
          ? ActivityEventName.collapseSessionList
          : ActivityEventName.expandSessionList,
        {
          permalinkAlias: activity.permalink?.alias,
          title: activity.name,
          url: activity.permalink?.shareUrl,
        }
      );

      return !isExpanded;
    });
  }

  return dateSummary.length > 0 ? (
    <StackedView>
      <div
        className="d-flex flex-row align-items-center"
        role="button"
        onClick={toggleExpanded}
      >
        <InfoRow
          className="flex-fill"
          iconClassName="fas fa-calendar-day text-body"
        >
          <div className="d-flex flex-row">
            {/* Date summary */}
            <div className="d-flex flex-row me-2">
              {/* Weekday (of next appointed at) */}
              {!!nextAppointedAtWeekday && (
                <TagTextView text={nextAppointedAtWeekday} />
              )}

              <div className="text-body">{dateSummary}</div>
            </div>

            {/* Time summary */}
            <div className="d-flex flex-row">
              {!!timeSummaryEmoji && (
                <TagTextView symbol="" text={timeSummaryEmoji} />
              )}

              {!!timeSummary && <div className="text-body">{timeSummary}</div>}
            </div>
          </div>
        </InfoRow>

        {isSessionListAvailable &&
          (isExpanded ? (
            <i className="fas fa-chevron-up text-body" />
          ) : (
            <i className="fas fa-chevron-down text-body" />
          ))}
      </div>

      {isSessionListAvailable && (
        <div className={`ms-4 ${isExpanded ? '' : 'd-none'}`}>
          {activity.sessions?.map((session, sessionIdx) => (
            <ActivitySessionRow
              key={session.id}
              index={sessionIdx}
              isUpcomingSession={sessionIdx === upcomingSessionIdx}
              session={session}
            />
          ))}
        </div>
      )}
    </StackedView>
  ) : null;
}

export default ActivitySessionList;
