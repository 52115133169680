import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';


import { extractDomain } from 'src/app/utils/url-utils';

import AppLogo from 'src/app/components/views/AppLogo/AppLogo';

import AbstractTouchable from './AbstractTouchable';

import './WebpageTouchable.css';


type DomainType =
  | 'any'
  | 'facebook'
  | 'instagram'
  | 'katalogos'
  | 'twitter'
  | 'youtube';

type Props = {
  title: string;
  url: string;
};

const WebpageTouchable = ({title, url}: Props) => {
  const {t} = useTranslation();

  const [hint, setHint] = useState('');

  const domainType = useMemo<DomainType>(() => {
    const domain = extractDomain(url);

    setHint(decodeURI(url));

    if (domain.endsWith('facebook.com')) {
      return 'facebook';
    }
    else if (domain.endsWith('instagram.com')) {
      return 'instagram';
    }
    else if (domain.endsWith('katalogos.app')) {
      return 'katalogos';
    }
    else if (domain.endsWith('twitter.com')) {
      return 'twitter';
    }
    else if (domain.endsWith('youtube.com') || domain.endsWith('youtu.be')) {
      return 'youtube';
    }
    else {
      return 'any';
    }
  }, [title, url]);

  const faIconClass = useMemo(() => {
    if (domainType === 'facebook') {
      return 'fab fa-facebook-square';
    }
    else if (domainType === 'instagram') {
      return 'fab fa-instagram';
    }
    else if (domainType === 'twitter') {
      return 'fab fa-twitter-square';
    }
    else if (domainType === 'youtube') {
      return 'fab fa-youtube';
    }

    return 'fas fa-link';
  }, [domainType]);

  const iconClass = useMemo(() => {
    if (domainType === 'facebook') {
      return 'app-WebpageTouchable-icon-facebook';
    } else if (domainType === 'instagram') {
      return 'app-WebpageTouchable-icon-instagram';
    } else if (domainType === 'twitter') {
      return 'app-WebpageTouchable-icon-twitter';
    } else if (domainType === 'youtube') {
      return 'app-WebpageTouchable-icon-youtube';
    }

    return '';
  }, [domainType]);

  return url.length > 0 ? (
    <AbstractTouchable
      icon={
        domainType === 'katalogos' ? (
          <AppLogo width="36px" />
        ) : (
          <i className={`${faIconClass} ${iconClass}`} />
        )
      }
      contentLabel={t('contact.webpage.urlLabel')}
      hint={hint}
      title={title || url}
      href={url}
    />
  ) : null;
};


export default WebpageTouchable;
