export enum ActivityTypeCode {
  BibleStudyGroup = 'BibleStudyGroup',
  Course = 'Course',
  Musical = 'Musical',
  PrayerMeeting = 'PrayerMeeting',
  Retreat = 'Retreat',
  Seminar = 'Seminar',
  Talk = 'Talk',
  Workshop = 'Workshop',
}

/**
 * A listing of all activity types
 */
export const filterableActivityTypeCodes = [
  ActivityTypeCode.Talk,
  ActivityTypeCode.Course,
  ActivityTypeCode.BibleStudyGroup,
];
