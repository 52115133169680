import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';


import { Activity, ActivityAttachment } from 'src/app/models/entity/Activity';

import StackedView from 'src/app/components/views/StackedView/StackedView';
import WebpageTouchable from 'src/app/components/views/Contact/WebpageTouchable';

import SectionHeader from './SectionHeader';


type Props = {
  activity: Activity;
};

const AttachmentSection = ({ activity }: Props) => {
  const { t } = useTranslation();

  const [attachments, setAttachments] = useState<ActivityAttachment[]>([]);

  useEffect(() => {
    setAttachments(activity.attachments || []);
  }, [activity.attachments]);

  return attachments?.length > 0 ? (
    <StackedView verticalSpacingSize="Large">
      <SectionHeader text={t('activityAttachment.label')} />

      {attachments.map((attachment, index) => (
        <Fragment key={index + '.' + attachment.url}>
          <WebpageTouchable title={attachment.title} url={attachment.url} />
        </Fragment>
      ))}
    </StackedView>
  ) : null;
};

export default AttachmentSection;
