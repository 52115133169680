import { useState } from 'react';

import appStore from 'src/assets/img/app-store-badge.svg';

import environment from 'src/environment';

import styles from './Download.module.css';

/**
 * https://developer.apple.com/app-store/marketing/guidelines/
 */
function DownloadOnAppStore() {
  const [appUrl] = useState(environment.iosAppUrl);

  return (
    <>
      <div className="d-flex flex-column justify-content-center">
        {appUrl ? (
          <a
            className={styles.providerBadge}
            href={appUrl}
            rel="noreferrer"
            target="_blank"
          >
            <img src={appStore} className="img-fluid" alt="從 App Store 下載" />
          </a>
        ) : (
          <div
            className={`border border-dark rounded d-flex flex-column justify-content-center ${styles.providerBadgePlaceholder}`}
          >
            iPhone 版本即將推出
            <br />
            <span className="text-muted">請耐心等待</span>
          </div>
        )}
      </div>

      <p className={styles.deviceReq}>要求 iOS 10 或以上</p>

      {appUrl && (
        <p className={`text-muted ${styles.footnote}`}>
          iPhone 和 App Store 是 Apple Inc. 在美國和其他國家或地區的註冊商標。
        </p>
      )}
    </>
  );
}

export default DownloadOnAppStore;
