import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';


import {Organization} from 'src/app/models/entity/Organization';
import {CoorganizerTypeCode} from 'src/app/models/entity/definition/CoorganizerType';

import InfoRow from 'src/app/components/views/InfoRow/InfoRow';


type Props = {
  coorganizerType?: CoorganizerTypeCode;
  organization: Organization;
};

const OrganizerRow = ({
  coorganizerType,
  organization,
}: Props) => {
  const {t, i18n} = useTranslation();

  const translatedCoorganizerType = useMemo(() => {
    switch (coorganizerType) {
      case CoorganizerTypeCode.Joint:
        return t('coorganizerType.jointCoorganizer.label');

      case CoorganizerTypeCode.Assisting:
        return t('coorganizerType.assistingCoorganizer.label');

      case CoorganizerTypeCode.VenueProvisioning:
        return t('coorganizerType.venueProvisioningCoorganizer.label');

      default:
        return t('coorganizerType.hostOrganizer.label');
    }
  }, [i18n.language, coorganizerType]);

  return (
    <InfoRow iconClassName="fas fa-at">
      <div className="text-body">{organization.name}</div>
    </InfoRow>
  );
};


export default OrganizerRow;
