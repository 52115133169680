import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AppLogo from '../AppLogo/AppLogo';

function NavBar() {
  const { t } = useTranslation();

  return (
    <>
      <nav
        className="navbar navbar-light"
        style={{ backgroundColor: '#5C1192' }}
      >
        <div className="container">
          <Link className="navbar-brand text-decoration-none" to="/">
            <div className="d-flex align-items-center">
              <AppLogo width="50px" />
              <div className="ms-2">
                <div className="text-light app-name">{t('appName')}</div>
                <div className="text-light app-caption">{t('appCaption')}</div>
              </div>
            </div>
          </Link>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
