import { useState } from 'react';

import environment from 'src/environment';

function AndroidAppEarlyAccess() {
  const [appUrl] = useState(environment.androidAppURL);

  return (
    <div className="mt-4 fs-5">
      <h2>預先體驗 Android App</h2>
      <p>
        《月初有道》應用程式尚在早期開發階段，《月初有道》開發者誠邀各位熱心於查考聖經、傳揚福音的弟兄姊妹，同來率先體驗、協助測試
        Android 程式之功能、穩定性。
      </p>
      <p className="m-0">如果：</p>
      <ul className="mt-0">
        <li>你樂於試用新應用程式及體驗新功能</li>
        <li>你能容忍程式出現錯誤，並不吝嗇於提供回饋意見</li>
        <li>你的 Android 設備已登入 Google Play</li>
      </ul>
      <p>請立即下載：</p>
      <p className="d-grid gap-2">
        <a
          className="btn btn-primary btn-lg btn-outlined"
          href={appUrl}
          rel="noopener noreferrer"
          target="_blank"
        >
          率先試用 <b>月初有道</b>
        </a>
      </p>
    </div>
  );
}

export default AndroidAppEarlyAccess;
