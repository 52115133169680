import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';


import { CoorganizerTypeCode } from 'src/app/models/entity/definition/CoorganizerType';
import { Activity, ActivityCoorganizer } from 'src/app/models/entity/Activity';

import StackedView from 'src/app/components/views/StackedView/StackedView';

import CoorganizerListing from './CoorganizerListing';
import OrganizerRow from './OrganizerRow';
import SectionHeader from './SectionHeader';


type Props = {
  activity: Activity;
}

const OrganizerSection = ({ activity }: Props) => {

  const { t } = useTranslation();

  const [jointCoorganizers, setJointCoorganizers] = useState<ActivityCoorganizer[]>([]);
  const [assistingCoorganizers, setAssistingCoorganizers] = useState<ActivityCoorganizer[]>([]);

  useEffect(() => {
    const coorganizers = activity.coorganizers || [];

    setJointCoorganizers(coorganizers.filter(coorganizer => coorganizer.type === CoorganizerTypeCode.Joint));
    setAssistingCoorganizers(coorganizers.filter(coorganizer => coorganizer.type === CoorganizerTypeCode.Assisting));
  }, [activity.coorganizers]);

  return (
    <StackedView>
      {activity.hostOrganization && jointCoorganizers.length === 0 && (
        <>
          {/* Host Organizer */}
          <SectionHeader text={t('coorganizerType.hostOrganizer.label')} />
          <OrganizerRow organization={activity.hostOrganization} />
        </>
      )}

      {/* If there is at least one joint-coorganizer, the host organizer is listed as such coorganizer */}
      {jointCoorganizers.length > 0 && (
        <>
          <SectionHeader text={t('coorganizerType.jointCoorganizer.label')} />

          {/* Host Organizer */}
          <OrganizerRow
            coorganizerType={CoorganizerTypeCode.Joint}
            organization={activity.hostOrganization}
          />

          {/* Joint Coorganizers */}
          <CoorganizerListing
            coorganizerType={CoorganizerTypeCode.Joint}
            coorganizers={jointCoorganizers}
          />
        </>
      )}

      {/* Assisting Coorganizers */}
      {assistingCoorganizers.length > 0 && (
        <CoorganizerListing
          coorganizerType={CoorganizerTypeCode.Assisting}
          coorganizers={assistingCoorganizers}
          showHeader
        />
      )}
    </StackedView>
  );
}


export default OrganizerSection;
