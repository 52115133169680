
import { ActivityTypeCode } from 'src/app/models/entity/definition/ActivityType';


export function translateActivityType(activityType: ActivityTypeCode): string {
  switch (activityType) {
    case ActivityTypeCode.BibleStudyGroup:
      return 'activityType.bibleStudyGroup';

    case ActivityTypeCode.Course:
      return 'activityType.course';

    case ActivityTypeCode.Musical:
      return 'activityType.musical';

    case ActivityTypeCode.PrayerMeeting:
      return 'activityType.prayerMeeting';

    case ActivityTypeCode.Retreat:
      return 'activityType.retreat';

    case ActivityTypeCode.Seminar:
      return 'activityType.seminar';

    case ActivityTypeCode.Talk:
      return 'activityType.talk';

    case ActivityTypeCode.Workshop:
      return 'activityType.workshop';

    default:
      // Others... not to translate it
      return '';
  }
}
