import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import AbstractTouchable from './AbstractTouchable';

import './WhatsappTouchable.css';

type Props = {
  enquiryMessage?: string;
  partyName: string;
  title?: string;
  whatsappNumber: string;
};

const WhatsappTouchable = ({
  enquiryMessage = '',
  partyName,
  title,
  whatsappNumber,
}: Props) => {
  const {t} = useTranslation();

  const href = useMemo(
    () => {
      if (!whatsappNumber) {
        return '';
      }

      const cleanedWhatsappNumber = whatsappNumber.replace(/[^+\d]/g, '');

      return `https://wa.me/${cleanedWhatsappNumber}?text=${encodeURIComponent(
        enquiryMessage
      )}`;
    },
    [whatsappNumber, enquiryMessage]
  );

  return whatsappNumber.length > 0 ? (
    <AbstractTouchable
      icon={
        <div className="app-WhatsappTouchable-iconContainer">
          <i className="fab fa-whatsapp app-WhatsappTouchable-icon" />
        </div>
      }
      contentLabel={t('contact.whatsapp.whatsappNumber')}
      contentToCopy={whatsappNumber}
      hint={whatsappNumber}
      href={href}
      title={title || whatsappNumber}
    />
  ) : null;
};


export default WhatsappTouchable;
