export enum TimePeriodCode {
  MORNING = 'Morning',
  AFTERNOON = 'Afternoon',
  NIGHT = 'Night',
}

export enum TimePeriodEmoji {
  MORNING = '⛅',
  AFTERNOON = '☀️',
  NIGHT = '🌙',
}

/**
 * A listing of all time period.
 *
 * The order matters.
 */
export const AllTimePeriods = [
  TimePeriodCode.MORNING,
  TimePeriodCode.AFTERNOON,
  TimePeriodCode.NIGHT,
];

export function toTimePeriodCode(hourIn24: number) {
  if (hourIn24 >= 4 && hourIn24 <= 12) {
    return TimePeriodCode.MORNING;
  } else if (hourIn24 > 12 && hourIn24 <= 18) {
    return TimePeriodCode.AFTERNOON;
  }

  return TimePeriodCode.NIGHT;
}

export function toTimePeriodEmoji(code: TimePeriodCode) {
  switch (code) {
    case TimePeriodCode.MORNING:
      return TimePeriodEmoji.MORNING;
    case TimePeriodCode.AFTERNOON:
      return TimePeriodEmoji.AFTERNOON;
    case TimePeriodCode.NIGHT:
    default:
      return TimePeriodEmoji.NIGHT;
  }
}
