import React from 'react';

type Props = {
  children?: React.ReactNode;
  className?: string;
};

const TagContainer = ({ children, className = '' }: Props) => {
  return <div className={`d-flex ${className}`}>{children}</div>;
};

export default TagContainer;
