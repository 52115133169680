import { useEffect, useState } from 'react';

import { resolvePermalink } from 'src/app/utils/permalinkable-utils';

/**
 * This custom hook helps resolve `scope` and `alias` from permalink.
 *
 * @see resolvePermalink
 */
export const usePermalinkResolver = (
  incomingScope: string,
  incomingAlias: string
) => {
  const [resolvedScope, setResolvedScope] = useState('');
  const [resolvedAlias, setResolvedAlias] = useState('');

  useEffect(() => {
    const { resolvedScope, resolvedAlias } = resolvePermalink(
      incomingScope,
      incomingAlias
    );

    setResolvedScope(resolvedScope);
    setResolvedAlias(resolvedAlias);
  }, [incomingScope, incomingAlias]);

  return {
    resolvedScope,
    resolvedAlias,
  };
};
