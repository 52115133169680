import { useTranslation } from 'react-i18next';


import logo from 'src/logo.svg';


type Props = {
  width: string;
};

function AppLogo({ width }: Props) {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'inline-block', width, backgroundColor: '#fff', borderRadius: '50%' }}>
      <img
        src={logo}
        className="img-fluid"
        alt={t('appName')}
        style={{ width: '100%', maxWidth: 240 }}
      />
    </div>
  );
}

AppLogo.defaultProps = {
  width: 'auto',
};

export default AppLogo;
