import { useMemo } from 'react';

import { DateTime } from 'luxon';

import { toLocaleDate, toLocaleDateTime } from 'src/app/utils/date-time-utils';

import useDeviceInfo from './useDeviceInfo';

type Format = 'dateAndTime' | 'dateOnly';

function useDateTimeFormatting(
  momentISO8601: string,
  format: Format = 'dateAndTime'
) {
  const { deviceTimeZone, in24Clock } = useDeviceInfo();

  const formattedValue = useMemo(() => {
    if (!momentISO8601) {
      return '';
    }

    const moment = DateTime.fromISO(momentISO8601).setZone(deviceTimeZone);

    if (format === 'dateOnly') {
      return toLocaleDate(moment, true);
    } else {
      return toLocaleDateTime(moment, in24Clock, true);
    }
  }, [deviceTimeZone, in24Clock, momentISO8601, format]);

  return formattedValue;
}

export default useDateTimeFormatting;
