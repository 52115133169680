import { combineReducers, configureStore, Action, ThunkAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux'


import activitiesReducer from './features/activity/activitiesSlice';


/**
 * https://redux.js.org/recipes/usage-with-typescript#usage-with-react-redux
 * https://react-redux.js.org/using-react-redux/static-typing
 * https://redux-toolkit.js.org/usage/usage-with-typescript
 * https://blog.logrocket.com/using-typescript-with-redux-toolkit/
 */

const rootReducer = combineReducers({
  activitiesReducer: activitiesReducer,
})

const store = configureStore({
  reducer: rootReducer,
});

export default store;

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;


export type AppDispatch = typeof store.dispatch;

// Export a hook that can be reused to resolve types
export const useAppDispatch = () => useDispatch<AppDispatch>();
