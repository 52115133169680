import { useState } from 'react';

import googlePlay from 'src/assets/img/google-play-badge.png';

import environment from 'src/environment';

import styles from './Download.module.css';

/**
 * https://play.google.com/intl/en_us/badges/
 */
function DownloadOnGooglePlay() {
  const [appUrl] = useState(environment.androidAppURL);

  return (
    <>
      <div className="d-flex flex-column justify-content-center">
        {appUrl ? (
          <a
            className={styles.providerBadge}
            href={appUrl}
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={googlePlay}
              className="img-fluid"
              alt="從 Google Play 下載"
            />
          </a>
        ) : (
          <div
            className={`border border-dark rounded d-flex flex-column justify-content-center ${styles.providerBadgePlaceholder}`}
          >
            Android 版本即將推出
            <br />
            <span className="text-muted">請耐心等待</span>
          </div>
        )}
      </div>

      <div className="text-muted">（率先試用版）</div>
      <p className={styles.deviceReq}>要求 Android 5.0 或以上</p>

      {appUrl && (
        <p className={`text-muted ${styles.footnote}`}>
          Google Play 和 Google Play 標誌均為 Google LLC 的商標。
        </p>
      )}
    </>
  );
}

export default DownloadOnGooglePlay;
