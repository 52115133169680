import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';


import { Individual } from 'src/app/models/entity/Individual';
import { ServantTypeCode } from 'src/app/models/entity/definition/ServantType';

import SectionHeader from './SectionHeader';
import InfoRow from 'src/app/components/views/InfoRow/InfoRow';


type Props = {
  servants: Individual[];
  servantType: ServantTypeCode;
  showHeader?: boolean;
};

/**
 * Component for rendering servants
 */
const ServantListing = ({
  servants,
  servantType,
  showHeader = false,
}: Props) => {
  const { t, i18n } = useTranslation();

  const translatedServantType = useMemo(() => {
    switch (servantType) {
      case ServantTypeCode.MajorSpeaker:
        return t('servantType.majorSpeaker.label');

      case ServantTypeCode.Respondent:
        return t('servantType.respondent.label');

      case ServantTypeCode.Instructor:
        return t('servantType.instructor.label');

      case ServantTypeCode.Guest:
        return t('servantType.guest.label');

      case ServantTypeCode.Interpreter:
        return t('servantType.interpreter.label');

      case ServantTypeCode.SignLanguageInterpreter:
        return t('servantType.signLanguageInterpreter.label');

      default:
        return '';
    }
  }, [i18n.language, servantType]);

  return (
    <div>
      {showHeader && translatedServantType.length > 0 && (
        <SectionHeader text={translatedServantType} />
      )}

      {servants.map((servant) => (
        <InfoRow iconClassName="fas fa-user text-body" key={servant.id}>
          <div className="text-body">
            {servant.displayName}

            {servant.abstract?.length > 0 && (
              <div className="text-muted">{servant.abstract}</div>
            )}
          </div>
        </InfoRow>
      ))}
    </div>
  );
};


export default ServantListing;
