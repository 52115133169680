import {useTranslation} from 'react-i18next';


import AbstractTouchable from './AbstractTouchable';


type Props = {
  partyName: string;
  telNumber: string;
  title?: string;
};

const TelTouchable = ({partyName, telNumber, title}: Props) => {
  const {t} = useTranslation();

  return telNumber.length > 0 ? (
    <AbstractTouchable
      icon={<i className="fas fa-phone" />}
      contentLabel={t('contact.tel.telNumber')}
      contentToCopy={telNumber}
      hint={telNumber}
      href={`tel:${telNumber}`}
      title={title || telNumber}
    />
  ) : null;
};

export default TelTouchable;
