import {DateTime} from 'luxon';

/**
 * The weekday index values correspond to the ODBC standard.
 */
export enum WeekdayCode {
  SUNDAY = '1',
  MONDAY = '2',
  TUESDAY = '3',
  WEDNESDAY = '4',
  THURSDAY = '5',
  FRIDAY = '6',
  SATURDAY = '7',
}

/**
 * A listing of all weekdays.
 *
 * The order does not matter.
 */
export const AllWeekdays = [
  WeekdayCode.SUNDAY,
  WeekdayCode.MONDAY,
  WeekdayCode.TUESDAY,
  WeekdayCode.WEDNESDAY,
  WeekdayCode.THURSDAY,
  WeekdayCode.FRIDAY,
  WeekdayCode.SATURDAY,
];

export const SUNDAY_REF = DateTime.fromISO('2018-04-01T00:00:00');

/**
 * https://moment.github.io/luxon/api-docs/index.html#datetimeweekday
 *
 * @param date
 * @returns
 */
export function toWeekdayCode(date: DateTime): WeekdayCode {
  switch (date.get('weekday')) {
    case 1:
      return WeekdayCode.MONDAY;
    case 2:
      return WeekdayCode.TUESDAY;
    case 3:
      return WeekdayCode.WEDNESDAY;
    case 4:
      return WeekdayCode.THURSDAY;
    case 5:
      return WeekdayCode.FRIDAY;
    case 6:
      return WeekdayCode.SATURDAY;
    case 7:
    default:
      return WeekdayCode.SUNDAY;
  }
}
