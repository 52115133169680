import qrCode from 'src/assets/img/onlink-to-katalogos-small.png';

function DownloadViaQRCode() {
  return (
    <>
      <img src={qrCode} style={{ width: 150 }} />
      <p className="mt-1">或掃描下載</p>
    </>
  );
}

export default DownloadViaQRCode;
