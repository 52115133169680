import React, { useMemo } from 'react';


import styles from './StackedView.module.css';

type VerticalSpacingSize = 'Large' | 'Medium';

type Props = {
  children?: React.ReactNode;
  className?: string;
  verticalSpacingSize: VerticalSpacingSize;
};

/**
 * This view comes with a positive `marginBottom`, suitable for giving spacing between views
 * stacked vertically.
 */
function StackedView({
  children,
  className,
  verticalSpacingSize,
}: Props) {

  const preferredSizeClass = useMemo(() => {
    if (verticalSpacingSize === 'Large') {
      return styles.large;
    }

    return '';
  }, [verticalSpacingSize]);

  return (
    <div className={`${styles.root} ${preferredSizeClass} ${className}`}>
      {children}
    </div>
  );
}

StackedView.defaultProps = {
  className: '',
  verticalSpacingSize: 'Medium',
};

export default StackedView;
