import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';


import { Activity } from 'src/app/models/entity/Activity';

import InfoRow from 'src/app/components/views/InfoRow/InfoRow';


import ActivityNavigateToHostButton from './ActivityNavigateToHostButton';

import styles from './ActivityFooter.module.css';

type Props = {
  activity: Activity;
}

const ActivityFooter = ({ activity }: Props) => {


  const { t } = useTranslation();

  const colorClassName = useMemo(
    () => (activity.isPaid === true ? 'app-fee-paid' : 'app-fee-free'),
    [activity.isPaid]
  );

  return (
    <div
      className={`d-flex align-items-center border-top bg-white ${styles.root}`}
    >
      {/* Fee */}
      <div className="flex-fill">
        <InfoRow iconClassName={`fas fa-dollar-sign ${colorClassName}`}>
          <div className={`${colorClassName} ${styles.feeContent}`}>
            {activity.isPaid === true && t('activityFee.paidActivity.label')}
            {activity.isPaid === false && t('activityFee.freeActivity.label')}
          </div>
        </InfoRow>
      </div>

      {activity.externalPrimaryUrl && (
        <ActivityNavigateToHostButton activity={activity} />
      )}
    </div>
  );
}



export default ActivityFooter;
