import { TimePeriodEmoji } from './../models/entity/definition/TimePeriod';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DateTime } from 'luxon';


import { Activity } from 'src/app/models/entity/Activity';
import {
  TimePeriodCode,
  toTimePeriodCode,
  toTimePeriodEmoji,
} from 'src/app/models/entity/definition/TimePeriod';
import {
  WeekdayCode,
  toWeekdayCode,
} from 'src/app/models/entity/definition/WeekdayCode';

import useDeviceInfo from './useDeviceInfo';
import { toLocaleDate, toLocaleTime } from '../utils/date-time-utils';


function isYearShown(date: DateTime) {
  return !date.hasSame(DateTime.now(), 'year');
}

/**
 * This hook helps evaluate and format date/time summary of an activity.
 *
 * It does not require the activity to be in "publishing" state.
 */
function useActivityDateTimeSummaryFormatter(activity: Activity) {
  const { t, i18n } = useTranslation();

  const { deviceTimeZone, in24Clock } = useDeviceInfo();

  // It is the number of sessions throughout which participants are expected to be "fully" attending.
  // Such number is intended to be displayed as hashtag.
  const [resolvedSessionCount, setResolvedSessionCount] = useState(0);

  const [nextAppointedAtWeekday, setNextAppointedAtWeekday] = useState('');
  const [nextAppointedAtWeekdayCode, setNextAppointedAtWeekdayCode] =
    useState<WeekdayCode>();

  const [dateSummary, setDateSummary] = useState('');
  const [dateSummaryA11yLabel, setDateSummaryA11yLabel] = useState('');

  const [timeSummaryCode, setTimeSummaryCode] = useState<TimePeriodCode>();
  const [timeSummaryEmoji, setTimeSummaryEmoji] = useState<TimePeriodEmoji>();

  const [timeSummary, setTimeSummary] = useState('');
  const [timeSummaryA11yLabel, setTimeSummaryA11yLabel] = useState('');

  const dateTimeSummaryA11yLabel = useMemo(
    () =>
      nextAppointedAtWeekday && dateSummaryA11yLabel && timeSummaryA11yLabel
        ? t('activity.sessions.dateTimeSummaryA11yLabelPattern', {
            dateSummary: t('activity.sessions.dateSummaryA11yLabelPattern', {
              weekday: nextAppointedAtWeekday,
              dateSummary: dateSummaryA11yLabel,
            }),
            timeSummary: timeSummaryA11yLabel,
          })
        : '',
    [
      i18n.language,
      nextAppointedAtWeekday,
      dateSummaryA11yLabel,
      timeSummaryA11yLabel,
    ]
  );

  const localizeDateSummaryFromSingleDate = (startedAt: DateTime) => {
    const localizedStartDate = toLocaleDate(startedAt, isYearShown(startedAt));

    setDateSummary(localizedStartDate);
    setDateSummaryA11yLabel(localizedStartDate);
  };

  const localizeDateSummaryFromDateRange = (
    startedAt: DateTime,
    endedAt: DateTime
  ) => {
    const isYearOfEndDateShown =
      isYearShown(endedAt) || endedAt.diff(startedAt, 'days').days >= 365;

    const localizedStartDate = toLocaleDate(startedAt, isYearShown(startedAt));

    const localizedEndDate = toLocaleDate(endedAt, isYearOfEndDateShown);

    setDateSummary(
      t('dateTime.durationRangePattern', {
        startedAt: localizedStartDate,
        endedAt: localizedEndDate,
      })
    );

    setDateSummaryA11yLabel(
      t('dateTime.durationRangeA11yLabelPattern', {
        startedAt: localizedStartDate,
        endedAt: localizedEndDate,
      })
    );
  };

  const localizeTimeSummary = (startedAt: DateTime, endedAt: DateTime) => {
    const timePeriodCode = toTimePeriodCode(startedAt.hour);

    setTimeSummaryCode(timePeriodCode);
    setTimeSummaryEmoji(toTimePeriodEmoji(timePeriodCode));

    const localizedStartedAt = toLocaleTime(startedAt, in24Clock);

    const localizedEndedAt = toLocaleTime(endedAt, in24Clock);

    setTimeSummary(
      t('dateTime.durationRangePattern', {
        startedAt: localizedStartedAt,
        endedAt: localizedEndedAt,
      })
    );

    setTimeSummaryA11yLabel(
      t('dateTime.durationRangeA11yLabelPattern', {
        startedAt: localizedStartedAt,
        endedAt: localizedEndedAt,
      })
    );
  };

  useEffect(() => {
    // format date/time summary, according to device's time zone and 24h vs 12h clock format

    setDateSummary('');
    setDateSummaryA11yLabel('');

    setTimeSummaryCode(undefined);
    setTimeSummaryEmoji(undefined);

    setTimeSummary('');
    setTimeSummaryA11yLabel('');

    if (
      !activity.firstSessionStartedAt ||
      !activity.firstSessionEndedAt ||
      !activity.lastSessionEndedAt
    ) {
      // Probably, the organizer has not finished filling session detail.
      return;
    }

    const firstSessionStartedAt = DateTime.fromISO(
      activity.firstSessionStartedAt
    ).setZone(deviceTimeZone);

    const firstSessionEndedAt = DateTime.fromISO(
      activity.firstSessionEndedAt
    ).setZone(deviceTimeZone);

    const lastSessionEndedAt = DateTime.fromISO(
      activity.lastSessionEndedAt
    ).setZone(deviceTimeZone);

    const sessionCount =
      activity.sessionCount || activity.sessions?.length || 0;
    if (sessionCount === 1) {
      const durationInHours = lastSessionEndedAt.diff(
        firstSessionStartedAt,
        'hours'
      ).hours;

      // If the only "session" lasts >= 1 days, it is unreasonable to count such "session" and display as hashtag.
      setResolvedSessionCount(durationInHours < 24 ? 1 : 0);
    } else {
      setResolvedSessionCount(sessionCount);
    }

    if (firstSessionStartedAt.hasSame(lastSessionEndedAt, 'day')) {
      // All sessions are held within same day (with respect to user's device timezone).

      // Construct date summary with only first session
      localizeDateSummaryFromSingleDate(firstSessionStartedAt);

      if (!activity.isSessionTimeHidden) {
        // Construct time summary with all sessions (held within same day)
        localizeTimeSummary(firstSessionStartedAt, lastSessionEndedAt);
      }
    } else {
      // The sessions span across multiple days (with respect to user's device timezone).

      // Construct date summary with the first session and last session
      localizeDateSummaryFromDateRange(
        firstSessionStartedAt,
        lastSessionEndedAt
      );

      if (!activity.isSessionTimeHidden) {
        // Use only the first session to construct time summary
        localizeTimeSummary(firstSessionStartedAt, firstSessionEndedAt);
      }
    }
  }, [
    deviceTimeZone,
    in24Clock,
    i18n.language,

    // necessary dependant props
    activity.id,

    // and explicitly list all other dependant props
    activity.sessionCount,
    activity.sessions?.length,
    activity.firstSessionStartedAt,
    activity.firstSessionEndedAt,
    activity.lastSessionEndedAt,
    activity.isSessionTimeHidden,
  ]);

  useEffect(() => {
    if (!activity.nextAppointedAt) {
      setNextAppointedAtWeekday('');
      setNextAppointedAtWeekdayCode(undefined);
      return;
    }

    const nextAppointedAt = DateTime.fromISO(activity.nextAppointedAt).setZone(
      deviceTimeZone
    );

    setNextAppointedAtWeekday(
      nextAppointedAt.toLocaleString({
        weekday: 'short',
      })
    );

    setNextAppointedAtWeekdayCode(toWeekdayCode(nextAppointedAt));
  }, [deviceTimeZone, activity.nextAppointedAt]);

  return {
    resolvedSessionCount,
    dateTimeSummaryA11yLabel,
    dateSummary,
    timeSummaryCode,
    timeSummaryEmoji,
    timeSummary,
    nextAppointedAtWeekday,
    nextAppointedAtWeekdayCode,
  };
}

export default useActivityDateTimeSummaryFormatter;
