import { useTranslation } from 'react-i18next';

import './CopyButton.css';

type Props = {
  /**
   * The text to copy.
   */
  content: string;

  contentLabel?: string;
};


const CopyButton = ({
  content,
  contentLabel = '',
}: Props) => {
  const {t} = useTranslation();

  const handleClick = () => {
    try {
      window.navigator.clipboard.writeText(content); // copy content to clipboard
    } catch (error) {
      console.error(error);
    }

    const notification =
      contentLabel.length > 0
        ? t('notification.copiedContent', {
            content: contentLabel,
          })
        : t('notification.copiedText');

    // TODO notify via snackbar

    // Such alert will take over the 'focus' of window and make it fail to copy text.
    // We need to postpone the alert in order to copy-to-clipboard.
    setTimeout(() => {
      alert(notification);
    }, 50);
  };;

  return (
    <button
      className="btn btn-outline-secondary app-CopyButton"
      onClick={handleClick}
    >
      <i className="far fa-copy" />
    </button>
  );
};

export default CopyButton;
