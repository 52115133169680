import { useMemo } from 'react';



/**
 * This custom hook helps provide UI feedback based on device's preference.
 */
function useDeviceInfo() {

  const deviceTimeZone = useMemo(
    () => Intl.DateTimeFormat().resolvedOptions().timeZone,
    []
  );

  const in24Clock = useMemo(
    () => !!Intl.DateTimeFormat().resolvedOptions().hour12,
    []
  );

  return {
    deviceTimeZone,
    in24Clock,
  };
}

export default useDeviceInfo;
