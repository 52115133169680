import React from 'react';
import { useTranslation } from 'react-i18next';



import { ActivitySession } from 'src/app/models/entity/ActivitySession';


import useActivitySessionDateTimeFormatter from 'src/app/hooks/useActivitySessionDateTimeFormatter';

import styles from './ActivitySessionRow.module.css';


type Props = {
  index: number,
  isUpcomingSession: boolean,
  session: ActivitySession;
}

const ActivitySessionRow = ({ index, isUpcomingSession, session }: Props) => {

  const { t } = useTranslation();

  const {
    formattedDuration,
    formattedOccurredAt,
    formattedWeekday,
    timePeriodEmoji,
  } = useActivitySessionDateTimeFormatter(session);

  return (
    <div className={styles.root}>
      <div
        className={`${styles.rowIndex} text-body ${
          isUpcomingSession ? styles.upcomingSessionTextStyles : ''
        }`}
      >
        #{index + 1}
      </div>
      <div style={{ flex: 1, flexDirection: 'column' }}>
        {session.name?.length > 0 && (
          <div
            className={`text-body ${
              isUpcomingSession ? styles.upcomingSessionTextStyles : ''
            }`}
          >
            {session.name}
          </div>
        )}

        <div className="text-body">{formattedOccurredAt}</div>

        <div className="d-flex flex-row">
          <div className="me-2 text-muted">{formattedWeekday} </div>

          {!!timePeriodEmoji && (
            <div className="me-2 text-muted">{timePeriodEmoji} </div>
          )}

          {!!formattedDuration && (
            <div className="text-muted ms-2">{formattedDuration}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActivitySessionRow;
