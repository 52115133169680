import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';

/*
import {
  createTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
*/

import './index.css';

import 'src/app/i18n';

import App from './App2';
import store from './app/redux/store';

import * as serviceWorker from './serviceWorker';


// https://material-ui.com/customization/theming/
/*
const theme = createTheme({
  palette: {
    primary: {
      light: '#5C1192',
      main: '#5C1192',
      dark: '#400b67',
      contrastText: '#fff',
    },
    secondary: {
      light: '#FFEA00',
      main: '#FFEA00',
      dark: '#E6DA51',
      contrastText: '#000',
    },
  },
});
*/


ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      {/* <ThemeProvider theme={theme}> */}
        <BrowserRouter>
          <App />
        </BrowserRouter>
      {/* </ThemeProvider> */}
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
