import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';


import { ActivityCoorganizer } from 'src/app/models/entity/Activity';
import { CoorganizerTypeCode } from 'src/app/models/entity/definition/CoorganizerType';

import OrganizerRow from './OrganizerRow';
import SectionHeader from './SectionHeader';


type Props = {
  coorganizers: ActivityCoorganizer[];
  coorganizerType: CoorganizerTypeCode;
  showHeader?: boolean;
};

/**
 * Component for rendering coorganizers
 */
const CoorganizerListing = ({
  coorganizers,
  coorganizerType,
  showHeader = false,
}: Props) => {
  const { t, i18n } = useTranslation();

  const translatedCoorganizerType = useMemo(() => {
    switch (coorganizerType) {
      case CoorganizerTypeCode.Joint:
        return t('coorganizerType.jointCoorganizer.label');

      case CoorganizerTypeCode.Assisting:
        return t('coorganizerType.assistingCoorganizer.label');

      case CoorganizerTypeCode.VenueProvisioning:
        return t('coorganizerType.venueProvisioningCoorganizer.label');

      default:
        return '';
    }
  }, [i18n.language, coorganizerType]);

  return (
    <div>
      {showHeader && translatedCoorganizerType.length > 0 && (
        <SectionHeader text={translatedCoorganizerType} />
      )}

      {coorganizers.map((coorganizer) => (
        <OrganizerRow
          key={coorganizer.id}
          coorganizerType={coorganizerType}
          organization={coorganizer.organization}
        />
      ))}
    </div>
  );
};


export default CoorganizerListing;
