import { useMemo } from 'react';

type Props = {
  text: string;
};

const SectionHeader = ({ text }: Props) => {
  const styles = useMemo(() => makeStyles(), []);

  return <div style={styles.textStyles}>{text}</div>;
};

const makeStyles = () => ({
  textStyles: {
    color: '#000',
    fontSize: 14,
    fontWeight: 600,
  },
});

export default SectionHeader;
