import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DateTime } from 'luxon';

import {
  toLocaleDate,
  toLocaleDateTime,
  toLocaleTime,
} from 'src/app/utils/date-time-utils';

import useDeviceInfo from './useDeviceInfo';
import { ActivitySession } from 'src/app/models/entity/ActivitySession';

import {
  toTimePeriodCode,
  toTimePeriodEmoji,
} from 'src/app/models/entity/definition/TimePeriod';

function isYearShown(date: DateTime) {
  return !date.hasSame(DateTime.now(), 'year');
}

function useActivitySessionDateTimeFormatter(session: ActivitySession) {
  const { t, i18n } = useTranslation();

  const { deviceTimeZone, in24Clock } = useDeviceInfo();

  const [formattedDuration, setFormattedDuration] = useState('');

  const [formattedOccurredAt, setFormattedOccurredAt] = useState('');
  const [formattedOccurredAtA11yLabel, setFormattedOccurredAtA11yLabel] =
    useState('');

  const [formattedWeekday, setFormattedWeekday] = useState('');
  const [timePeriodEmoji, setTimePeriodEmoji] = useState('');

  useEffect(() => {
    const startedAt = DateTime.fromISO(session.startedAt).setZone(
      deviceTimeZone
    );
    const isStartedAtYearShown = isYearShown(startedAt);

    const endedAt = DateTime.fromISO(session.endedAt).setZone(deviceTimeZone);
    const isEndedAtYearShown = isYearShown(endedAt);

    const durationInMinutes = Math.round(
      endedAt.diff(startedAt, 'minutes').minutes
    );
    if (durationInMinutes < 60) {
      // less than 1 hour
      setFormattedDuration(
        t('activity.session.durationInMinutes', {
          minute: durationInMinutes,
        })
      );
    } else if (durationInMinutes <= 60 * 23.5) {
      // less than or equal to 23.5 hours
      setFormattedDuration(
        t('activity.session.durationInHours', {
          hour:
            durationInMinutes % 60 === 0
              ? (durationInMinutes / 60).toFixed(0)
              : (durationInMinutes / 60.0).toFixed(1),
        })
      );
    } else {
      const durationInDays = endedAt.diff(startedAt, 'days').days;
      setFormattedDuration(
        t('activity.session.durationInDays', {
          day: Math.round(durationInDays),
        })
      );
    }

    if (startedAt.hasSame(endedAt, 'day')) {
      // The session is held within the same day (with respect to user's device timezone).

      if (session.isTimeHidden) {
        const startedAtLocalized = toLocaleDate(
          startedAt,
          isStartedAtYearShown
        );
        setFormattedOccurredAt(startedAtLocalized);
        setFormattedOccurredAtA11yLabel(startedAtLocalized);
      } else {
        const startedAtLocalized = toLocaleDateTime(
          startedAt,
          in24Clock,
          isStartedAtYearShown
        );
        const endedAtLocalized = toLocaleTime(endedAt, in24Clock);

        setFormattedOccurredAt(
          t('dateTime.durationRangePattern', {
            startedAt: startedAtLocalized,
            endedAt: endedAtLocalized,
          })
        );

        setFormattedOccurredAtA11yLabel(
          t('dateTime.durationRangeA11yLabelPattern', {
            startedAt: startedAtLocalized,
            endedAt: endedAtLocalized,
          })
        );
      }
    } else {
      // The session spans across multiple days (with respect to user's device timezone).

      const startedAtLocalized = session.isTimeHidden
        ? toLocaleDate(startedAt, isStartedAtYearShown)
        : toLocaleDateTime(startedAt, in24Clock, isStartedAtYearShown);
      const endedAtLocalized = session.isTimeHidden
        ? toLocaleDate(endedAt, isEndedAtYearShown)
        : toLocaleDateTime(endedAt, in24Clock, isEndedAtYearShown);

      setFormattedOccurredAt(
        t('dateTime.durationRangePattern', {
          startedAt: startedAtLocalized,
          endedAt: endedAtLocalized,
        })
      );

      setFormattedOccurredAtA11yLabel(
        t('dateTime.durationRangeA11yLabelPattern', {
          startedAt: startedAtLocalized,
          endedAt: endedAtLocalized,
        })
      );
    }

    if (session.isTimeHidden) {
      setTimePeriodEmoji('');
    } else {
      setTimePeriodEmoji(toTimePeriodEmoji(toTimePeriodCode(startedAt.hour)));
    }

    setFormattedWeekday(startedAt.toLocaleString({ weekday: 'short' }));
  }, [
    i18n.language,
    deviceTimeZone,
    in24Clock,
    session.startedAt,
    session.endedAt,
    session.isTimeHidden,
  ]);

  return {
    formattedDuration,
    formattedOccurredAt,
    formattedOccurredAtA11yLabel,
    formattedWeekday,
    timePeriodEmoji,
  };
}

export default useActivitySessionDateTimeFormatter;
