import { useTranslation } from 'react-i18next';


import AppFeature from './views/AppFeature';
import DownloadOnAppStore from './views/DownloadOnAppStore';
import DownloadOnGooglePlay from './views/DownloadOnGooglePlay';
import DownloadViaQRCode from './views/DownloadViaQRCode';
import AndroidAppEarlyAccess from './views/AndroidAppEarlyAccess';

function Home() {
  const { t } = useTranslation();

  return (
    <>
      <div className="container">
        <div className="row py-lg-2">
          <div className="col col-lg-8 m-lg-auto">
            <p className="my-3 my-lg-5 fs-4 app-line-breakable">
              {t('appDescription')}
            </p>
          </div>
        </div>
      </div>

      <div className="pt-5 pb-4" style={{ backgroundColor: '#F0F0F0' }}>
        <div className="container">
          <div className="row justify-content-around align-items-center text-center">
            <div className="col-12 col-md-4">
              <DownloadOnGooglePlay />
            </div>

            <div className="col-12 mt-5 col-md-4 mt-md-0">
              <DownloadOnAppStore />
            </div>

            <div className="col-12 mt-5 col-md-4 mt-md-0">
              <DownloadViaQRCode />
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <AppFeature />
      </div>

      <div className="container mt-5">
        <AndroidAppEarlyAccess />
      </div>
    </>
  );
}

export default Home;
