import AbstractTouchable from './AbstractTouchable';


type Props = {
  content: string;
  title?: string;
};

const PlainTextUntouchable = ({content, title}: Props) => {
  return content.length > 0 ? (
    <AbstractTouchable
      icon={<i className="fas fa-info" />}
      contentToCopy={content}
      hint={content}
      title={title || content}
    />
  ) : null;
};

export default PlainTextUntouchable;
