import { useEffect, useState } from 'react';


import { ServantTypeCode } from 'src/app/models/entity/definition/ServantType';
import { Activity } from 'src/app/models/entity/Activity';
import { Individual } from 'src/app/models/entity/Individual';

import StackedView from 'src/app/components/views/StackedView/StackedView';

import ServantListing from './ServantListing';


type Props = {
  activity: Activity;
}

const ServantSection = ({ activity }: Props) => {

  const [servantTotalCount, setServantTotalCount] = useState(0);

  const [majorSpeakers, setMajorSpeakers] = useState<Individual[]>([]);
  const [respondents, setRespondents] = useState<Individual[]>([]);
  const [interpreters, setInterpreters] = useState<Individual[]>([]);
  const [signLanguageInterpreters, setSignLanguageInterpreters] = useState<Individual[]>([]);


  useEffect(() => {
    const servants = activity.servants || activity.hostingServants || [];
    setServantTotalCount(servants.length);

    setMajorSpeakers(servants.filter(servant => servant.servantType == ServantTypeCode.MajorSpeaker));
    setRespondents(servants.filter(servant => servant.servantType == ServantTypeCode.Respondent));
    setInterpreters(servants.filter(servant => servant.servantType == ServantTypeCode.Interpreter));
    setSignLanguageInterpreters(servants.filter(servant => servant.servantType == ServantTypeCode.SignLanguageInterpreter));
  }, [activity.servants, activity.hostingServants]);


  return (
    <StackedView>
      {majorSpeakers?.length > 0 && (
        <ServantListing
          servants={majorSpeakers}
          servantType={ServantTypeCode.MajorSpeaker}
          showHeader={servantTotalCount > 1}
        />
      )}

      {respondents?.length > 0 && (
        <ServantListing
          servants={respondents}
          servantType={ServantTypeCode.Respondent}
          showHeader
        />
      )}

      {interpreters?.length > 0 && (
        <ServantListing
          servants={interpreters}
          servantType={ServantTypeCode.Interpreter}
          showHeader
        />
      )}

      {signLanguageInterpreters?.length > 0 && (
        <ServantListing
          servants={signLanguageInterpreters}
          servantType={ServantTypeCode.SignLanguageInterpreter}
          showHeader
        />
      )}
    </StackedView>
  );
}


export default ServantSection;
