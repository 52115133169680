import { useEffect, useState } from 'react';

import { Activity } from 'src/app/models/entity/Activity';
import { OrganizationVenue } from 'src/app/models/entity/OrganizationVenue';

import StackedView from 'src/app/components/views/StackedView/StackedView';

import VenueRow from './VenueRow';


type Props = {
  activity: Activity;
}

const VenueSection = ({ activity }: Props) => {

  const [majorVenue, setMajorVenue] = useState<OrganizationVenue>();

  const [secondaryVenues, setSecondaryVenues] = useState<OrganizationVenue[]>([]);

  useEffect(() => {
    const majorVenue = activity.majorVenue;

    setMajorVenue(activity.majorVenue);

    const secondaryVenues = (activity.coorganizers || [])
      .map(coorganizer => coorganizer.venues || [])
      .reduce((a, b) => a.concat(b), [])
      .filter(venue => venue.id !== majorVenue?.id);

    setSecondaryVenues(secondaryVenues);
  }, [activity.majorVenue, activity.coorganizers]);


  return activity.isOnsite ? (
    <StackedView>
      {/* Major venue */}
      {majorVenue != null && <VenueRow venue={majorVenue} />}

      {/* Secondary venues */}
      {secondaryVenues.map(venue => (
        <VenueRow key={venue.id} venue={venue} />
      ))}
    </StackedView>
  ) : null;
}


export default VenueSection;
