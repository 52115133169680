export enum EnquiryTypeCode {
  email = 'email',
  fax = 'fax',
  tel = 'tel',
  whatsapp = 'whatsapp',
}

export type EnquiryType = {
  code: EnquiryTypeCode;
  labelKey: string;
}
