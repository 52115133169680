import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function Footer() {
  const { t } = useTranslation();

  return (
    <div className="container my-5 border-top pt-3">
      <div className="row justify-content-center align-items-center">
        <div className="col-12 col-lg">
          <div className="d-flex flex-column flex-lg-row fs-5">
            <div className="ms-3">
              <a href="mailto:info@katalogos.app">
                <i className="far fa-envelope" />
              </a>
            </div>

            {/* <div className="ms-3">
              <i className="fab fa-facebook" />
            </div>

            <div className="ms-3">
              <i className="fab fa-instagram-square" />
            </div>

            <div className="ms-3">
              <i className="fab fa-whatsapp" />
            </div> */}
          </div>
        </div>

        <div className="col-12 mt-3 col-lg mt-lg-0 text-lg-center">
          <div className="mx-3">
            <Link to="/privacy">{t('site.footer.privacy')}</Link>
          </div>
        </div>

        <div className="col-12 mt-3 col-lg mt-lg-0 text-lg-end">
          <div className="mx-3">&copy; 2022 {t('siteTitle')}</div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
