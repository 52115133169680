import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AxiosError, AxiosResponse } from 'axios';

import { DateTime } from 'luxon';


import environment from 'src/environment';

import { AppThunk, RootState } from 'src/app/redux/store';

import { Activity } from 'src/app/models/entity/Activity';
import {
  ActivityTypeCode,
  filterableActivityTypeCodes,
} from 'src/app/models/entity/definition/ActivityType';

import {
  ListActivityRequest,
  ParticipationMode,
} from 'src/app/models/request/activity/ListActivityRequest';
import { ListActivityResponse } from 'src/app/models/response/activity/ListActivityResponse';

import { resolvePermalink } from 'src/app/utils/permalinkable-utils';
/*
import { listActivity } from 'src/app/utils/activity-api-utils';
*/

export type ActivityListFilters = {
  mode: ParticipationMode;
  activityTypes: ActivityTypeCode[];
};


type SliceState = {
  /**
   * List of activities to be shown on screen
   */
  activities: Activity[];

  /**
   * Whether the filter panel is opened
   */
  isFilterPanelOpened: boolean;

  filters: ActivityListFilters | undefined;

  isFiltered: boolean;

  /**
   * The time when the app successfully fetched some activities, in string of ISO 8601 format.
   */
  lastFetchedAt: string;

  /**
   * Whether the app is fetching "first page of activities"
   */
  isFetchingFirstPage: boolean;

  /**
   * Such data needs not be persisted into local storage.
   */
  hasApiErrorOnFetchingFirstPage: boolean;

  /**
   * Such data needs not be persisted into local storage.
   */
  badAxiosResponseOnFetchingFirstPage: AxiosResponse | undefined;

  canFetchNextPage: boolean;

  /**
   * Whether the app is fetching "subsequent page of activities"
   */
  isFetchingNextPage: boolean;

  /**
   * Such data needs not be persisted into local storage.
   */
  hasApiErrorOnFetchingNextPage: boolean;

  /**
   * Such data needs not be persisted into local storage.
   */
  badAxiosResponseOnFetchingNextPage: AxiosResponse | undefined;
};

const initialState: SliceState = {
  activities: [],

  isFilterPanelOpened: false,

  filters: undefined,

  isFiltered: false,

  lastFetchedAt: '',

  isFetchingFirstPage: false,
  hasApiErrorOnFetchingFirstPage: false,
  badAxiosResponseOnFetchingFirstPage: undefined,

  canFetchNextPage: true,

  isFetchingNextPage: false,
  hasApiErrorOnFetchingNextPage: false,
  badAxiosResponseOnFetchingNextPage: undefined,
};

export const activitySlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    toggleFilterPanel: (state) => {
      state.isFilterPanelOpened = !state.isFilterPanelOpened;
    },

    willApplyFilter: (state, action: PayloadAction<ActivityListFilters>) => {
      state.filters = action.payload;
      state.isFiltered =
        !!action.payload.mode ||
        (action.payload.activityTypes
          ? action.payload.activityTypes.length > 0 &&
            action.payload.activityTypes.length < filterableActivityTypeCodes.length
          : false);
    },

    willFetchFirstPage: (state) => {
      state.isFetchingFirstPage = true;

      // clear any previous errors
      state.hasApiErrorOnFetchingFirstPage = false;
      state.badAxiosResponseOnFetchingFirstPage = undefined;
    },
    fetchedFirstPage: (state, action: PayloadAction<ListActivityResponse>) => {
      state.activities = action.payload.data;
      state.lastFetchedAt = DateTime.now().toISO();

      state.isFetchingFirstPage = false;
      state.canFetchNextPage = action.payload.data.length === action.payload.pageSize;
    },
    failedFetchFirstPage: (state, action: PayloadAction<AxiosResponse | undefined>) => {
      state.isFetchingFirstPage = false;
      state.hasApiErrorOnFetchingFirstPage = true;
      state.badAxiosResponseOnFetchingFirstPage = action.payload;
    },
    clearErrorOnFetchingFirstPage: (state) => {
      state.hasApiErrorOnFetchingFirstPage = false;
      state.badAxiosResponseOnFetchingFirstPage = undefined;
    },

    willFetchNextPage: (state) => {
      state.isFetchingNextPage = true;

      // clear any previous errors
      state.hasApiErrorOnFetchingNextPage = false;
      state.badAxiosResponseOnFetchingNextPage = undefined;
    },
    fetchedNextPage: (state, action: PayloadAction<ListActivityResponse>) => {
      state.activities = state.activities.concat(action.payload.data); // append items and return as new array
      state.lastFetchedAt = DateTime.now().toISO();

      state.isFetchingNextPage = false;
      state.canFetchNextPage = action.payload.data.length === action.payload.pageSize;
    },
    failedFetchNextPage: (state, action: PayloadAction<AxiosResponse | undefined>) => {
      state.isFetchingNextPage = false;
      state.hasApiErrorOnFetchingNextPage = true;
      state.badAxiosResponseOnFetchingNextPage = action.payload;
    },

  },
});


// Actions:

const {
  willApplyFilter,

  willFetchFirstPage,
  fetchedFirstPage,
  failedFetchFirstPage,

  willFetchNextPage,
  fetchedNextPage,
  failedFetchNextPage,
} = activitySlice.actions;

/*
export const applyFilter =
  (locale: string, filters: ActivityListFilters): AppThunk =>
  (dispatch, getState) => {
    dispatch(willApplyFilter(filters));
    dispatch(fetchFirstPageOfActivities(locale, true));
  };

export const fetchFirstPageOfActivities =
  (
    locale: string,
    forceFetch: boolean = false,
  ): AppThunk =>
  (dispatch, getState) => {
    // console.log('Going to fetch first page of activities.');

    const state = getState().activitiesReducer;

    if (state.isFetchingFirstPage) {
      // console.log('\t App is already fetching first page of activities. Aborted.');
      return;
    }

    if (!forceFetch) {
      const stateLastFetchedAt = state.lastFetchedAt;
      const lastFetchedAt = stateLastFetchedAt
        ? DateTime.fromISO(stateLastFetchedAt)
        : null;

      if (lastFetchedAt) {
        const now = DateTime.now();
        const diffInMinutes = now.diff(lastFetchedAt, 'minutes').toObject();
        if (
          diffInMinutes.minutes &&
          diffInMinutes.minutes <
            environment.activitiesFromLocalStoreTTLInMinutes
        ) {
          // console.log('\t Data is still fresh. Aborted.');
          return;
        }
      }
    }

    dispatch(willFetchFirstPage());

    const request: ListActivityRequest = {
      locale,
      mode: state.filters?.mode,
      activityTypes: state.filters?.activityTypes,
    };

    return listActivity(request)
      .then(response => {
        dispatch(fetchedFirstPage(response));
      })
      .catch((e: AxiosError) => {
        dispatch(failedFetchFirstPage(e.response));
      });
  };

export const fetchNextPageOfActivities = (locale: string): AppThunk => (dispatch, getState) => {
  // console.log('Going to fetch next page of activities.');

  const state = getState().activitiesReducer;

  if (state.isFetchingNextPage) {
    // console.log('\t App is already fetching next page of activities. Aborted.');
    return;
  }
  else if (!state.canFetchNextPage) {
    // console.log('\t Already fetched all activities. Aborted.');
    return;
  }

  const activities = state.activities;

  dispatch(willFetchNextPage());

  const since = activities[activities.length - 1].firstSessionStartedAt;

  const skippedIds = activities.filter(activity => activity.firstSessionStartedAt === since)
    .map(activity => activity.id);

    const request: ListActivityRequest = {
      locale,
      mode: state.filters?.mode,
      activityTypes: state.filters?.activityTypes,
      since,
      skippedIds,
    };

  return listActivity(request)
    .then(response => {
      dispatch(fetchedNextPage(response));
    })
    .catch((e: AxiosError) => {
      dispatch(failedFetchNextPage(e.response));
    });
};
*/

export const {
  toggleFilterPanel,

  clearErrorOnFetchingFirstPage,
} = activitySlice.actions;


// Selectors:

export const selectActivities = (state: RootState) => state.activitiesReducer.activities;

export const selectFilterPanelOpened = (state: RootState) => state.activitiesReducer.isFilterPanelOpened;
export const selectFilters = (state: RootState) => state.activitiesReducer.filters;
export const selectIsFiltered = (state: RootState) => state.activitiesReducer.isFiltered;

export const selectIsFetchingFirstPage = (state: RootState) => state.activitiesReducer.isFetchingFirstPage;
export const selectHasApiErrorOnFetchingFirstPage = (state: RootState) => state.activitiesReducer.hasApiErrorOnFetchingFirstPage;
export const selectBadAxiosResponseOnFetchingFirstPage = (state: RootState) => state.activitiesReducer.badAxiosResponseOnFetchingFirstPage;

export const selectCanFetchNextPage = (state: RootState) => state.activitiesReducer.canFetchNextPage;

export const selectIsFetchingNextPage = (state: RootState) => state.activitiesReducer.isFetchingNextPage;
export const selectHasApiErrorOnFetchingNextPage = (state: RootState) => state.activitiesReducer.hasApiErrorOnFetchingNextPage;
export const selectBadAxiosResponseOnFetchingNextPage = (state: RootState) => state.activitiesReducer.badAxiosResponseOnFetchingNextPage;


export const selectActivity = (incomingScope: string, incomingAlias: string) =>
  (state: RootState) => {
    const {
      resolvedScope,
      resolvedAlias,
    } = resolvePermalink(incomingScope, incomingAlias);

    // TODO Respect `resolvedScope`

    return state.activitiesReducer.activities.find(activity => resolvedAlias === activity.permalink?.alias)
  };


export default activitySlice.reducer;
