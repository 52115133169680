

/**
 * It helps resolve `scope` and `alias` from permalink, in these formats:
 * - '@hello/world',
 * - 'world';
 *
 * where:
 * - '@hello' is a optional scope (of an organization / individual / ...),
 * - 'world' is an alias (of an activity / subject / series / ...)
 */
export const resolvePermalink = (incomingScope: string, incomingAlias: string) => {
  let resolvedScope = '';
  let resolvedAlias = '';

  if (/^@/.test(incomingScope)) {
    resolvedScope = incomingScope.replace(/^@/, '');
    resolvedAlias = incomingAlias;
  }
  else if (incomingScope) {
    resolvedAlias = incomingScope;
  }
  else if (incomingAlias) {
    resolvedAlias = incomingAlias;
  }

  return {
    resolvedScope,
    resolvedAlias,
  };
}
