import { Permalinkable } from 'src/app/models/entity/Permalinkable';

import { ListActivityRequest } from 'src/app/models/request/activity/ListActivityRequest';

import { ListActivityResponse } from 'src/app/models/response/activity/ListActivityResponse';
import { ShowPermalinkResponse } from 'src/app/models/response/activity/ShowPermalinkResponse';

import { apiGet, apiPost } from './api-utils';

/*
export function listActivity(
  request: ListActivityRequest,
): Promise<ListActivityResponse> {
  return apiPost<ListActivityResponse>('activity', request);
}
*/

export function showPermalinkable(permalinkAlias: string, locale: string): Promise<Permalinkable> {
  const params = { locale };

  return apiGet<ShowPermalinkResponse>(`at/${permalinkAlias}`, params)
    .then((response: ShowPermalinkResponse) => response.data);
}
