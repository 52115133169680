import {useTranslation} from 'react-i18next';


import AbstractTouchable from './AbstractTouchable';


type Props = {
  faxNumber: string;
  partyName: string;
  title?: string;
};

const FaxTouchable = ({faxNumber, title, partyName}: Props) => {
  const {t, i18n} = useTranslation();

  return faxNumber.length > 0 ? (
    <AbstractTouchable
      icon={<i className="fas fa-fax" />}
      contentLabel={t('contact.fax.faxNumber')}
      contentToCopy={faxNumber}
      hint={faxNumber}
      title={title || faxNumber}
    />
  ) : null;
};

export default FaxTouchable;
