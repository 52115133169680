import React from 'react';


import styles from './TagView.module.css';

export interface TagTextViewProps {
  className?: string;
  symbol?: string;
  text: string;
};

function TagTextView({
  className,
  symbol = '#',
  text,
}: TagTextViewProps) {

  return (
    <div className={`me-2 ${className} ${styles.hashTag}`}>
      {symbol}
      {text}
    </div>
  );
};

export default TagTextView;
