import {DateTime} from 'luxon';

/**
 * A method to format date only
 * @param {DateTime} moment
 */
export const toLocaleDate = (moment: DateTime, isYearShown?: boolean) =>
  moment.toLocaleString({
    day: 'numeric',
    month: 'short',
    year: isYearShown ? 'numeric' : undefined,
  });

/**
 * A method to format date only
 * @param {string} momentISO8601 Expected an empty string or ISO 8601 string
 * @param {string} targetTimeZone
 */
export const toLocaleDateFromISO8601 = (
  momentISO8601: string,
  targetTimeZone: string,
  isYearShown?: boolean,
) => {
  if (!momentISO8601) {
    return '';
  }

  const moment = DateTime.fromISO(momentISO8601).setZone(targetTimeZone);
  return toLocaleDate(moment, isYearShown);
};

/**
 * A method to format time only
 * @param {DateTime} moment
 */
export const toLocaleTime = (moment: DateTime, in24Clock: boolean) =>
  moment.toLocaleString({
    hour: 'numeric',
    minute: 'numeric',
    hour12: !in24Clock,
  });

/**
 * A method to format time only
 * @param {string} momentISO8601 Expected an empty string or ISO 8601 string
 */
export const toLocaleTimeFromISO8601 = (
  momentISO8601: string,
  targetTimeZone: string,
  in24Clock: boolean,
) => {
  if (!momentISO8601) {
    return '';
  }

  const moment = DateTime.fromISO(momentISO8601).setZone(targetTimeZone);
  return toLocaleTime(moment, in24Clock);
};

/**
 * A method to format date and time
 * @param {DateTime} moment
 */
export const toLocaleDateTime = (
  moment: DateTime,
  in24Clock: boolean,
  isYearShown?: boolean,
) =>
  moment.toLocaleString({
    year: isYearShown ? 'numeric' : undefined,
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: !in24Clock,
  });

/**
 * A method to format date and time
 * @param {string} momentISO8601 Expected an empty string or ISO 8601 string
 */
export const toLocaleDateTimeFromISO8601 = (
  momentISO8601: string,
  targetTimeZone: string,
  in24Clock: boolean,
) => {
  if (!momentISO8601) {
    return '';
  }

  const moment = DateTime.fromISO(momentISO8601).setZone(targetTimeZone);
  return toLocaleDateTime(moment, in24Clock);
};
