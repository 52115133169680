/**
 * To test whether a string looks like an url.
 * @param val
 * @returns
 */
export function isUrlAlike(val: string) {
  return /^https?:\/\/([^/]+)/i.test(val);
}

/**
 * Extract domain from an URL
 * @param url
 * @returns
 */
export function extractDomain(url: string) {
  const matches = url?.match(/^https?:\/\/([^/]+)/i);
  return (matches && matches[1]) || '';
}
