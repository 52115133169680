import { initializeApp } from 'firebase/app';


import firebaseConfig from 'src/assets/config/firebaseConfig';


// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
