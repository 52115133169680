/**
 * The environmental configurations from `.env` are parsed and interpreted as string by react.
 *
 * This JSON serves as a bridge between application code (javascript / typescript) and the `.env`.
 * It also provides proper types and documentation to the configurations.
 */
const environment = {
  /**
   * Android App URL
   */
  androidAppURL: process.env.REACT_APP_ANDROID_APP_URL || '',

  /**
   * iOS App URL
   */
  iosAppUrl: process.env.REACT_APP_IOS_APP_URL || '',

  /**
   * The base path for API calls
   * Expected value:
   * - PROD: https://api.katalogos.app/
   * - TEST: https://aleph-api.katalogos.app/
   * - LOCAL: http://api.katalogos.localhost/
   */
  apiBase: process.env.REACT_APP_APP_API_BASE_URL,

  /**
   * Time-to-live of the activities data from local store, in minutes
   */
  activitiesFromLocalStoreTTLInMinutes: Number(
    process.env.REACT_APP_APP_ACTIVITIES_FROM_LOCAL_STORE_TTL_IN_MINUTES
  ),
};

export default environment;
