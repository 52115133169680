const firebaseConfig = {
  apiKey: 'AIzaSyCYtDGncdspfr255XzopgoqyeTvvWfymsw',
  authDomain: 'quiet-spirit-256204.firebaseapp.com',
  databaseURL: 'https://quiet-spirit-256204.firebaseio.com',
  projectId: 'quiet-spirit-256204',
  storageBucket: 'quiet-spirit-256204.appspot.com',
  messagingSenderId: '585466620595',
  appId: '1:585466620595:web:edecb31e85e2f24b211ec6',
  measurementId: 'G-LM13XQ30QR',
};

export default firebaseConfig;
