import {
  AnalyticsCallOptions,
  CustomEventName,
  getAnalytics,
  logEvent as firebaseLogEvent,
} from 'firebase/analytics';


import firebase from 'src/firebase';

/**
 * It is a wrapper for the original `logEvent()` from `firebase`.
 *
 * @param eventName
 * @param eventParams
 * @param options
 */
function logEvent<T extends string>(
  eventName: CustomEventName<T>,
  eventParams?: {
    [key: string]: any;
  },
  options?: AnalyticsCallOptions
) {
  const analytics = getAnalytics(firebase);

  firebaseLogEvent(analytics, eventName, eventParams, options);
}

export default logEvent;
