import React, { useEffect, useState } from 'react';


import { isUrlAlike } from 'src/app/utils/url-utils';

import CopyButton from '../CopyButton/CopyButton';

import './AbstractTouchable.css';


type Props = {
  /**
   * Used only when `contentToCopy` is non-empty
   */
  contentLabel?: string;

  contentToCopy?: string;

  /**
   * The secondary text content
   */
  hint?: string;

  /**
   * The url of link
   */
  href?: string;

  icon: React.ReactNode;

  /**
   * The primary text content
   */
  title: string;
};

const AbstractTouchable = ({
  contentLabel = '',
  contentToCopy = '',
  hint = '',
  href = '',
  icon,
  title,
}: Props) => {

  return (
    <div className="d-flex align-items-center">
      <div className="flex-fill">
        {href ? (
          <a
            href={href}
            className="d-block app-AbstractTouchable-anchor"
            target="_blank"
          >
            <Content hint={hint} icon={icon} title={title} />
          </a>
        ) : (
          <Content hint={hint} icon={icon} title={title} />
        )}
      </div>

      {contentToCopy.length > 0 && (
        <div className="ms-1">
          <CopyButton contentLabel={contentLabel} content={contentToCopy} />
        </div>
      )}
    </div>
  );
};

interface ContentProps {
  /**
   * The secondary text content
   */
  hint: string;

  icon: React.ReactNode;

  /**
   * The primary text content
   */
  title: string;
}

const Content = ({
  hint,
  icon,
  title,
}: ContentProps) => {
  const [isHintVisible, setHintVisible] = useState(false);
  const [resolvedHint, setResolvedHint] = useState('');

  useEffect(() => {
    if (hint.length > 0) {
      // Hint is visible only when it is different from title.
      setHintVisible(title !== hint);

      if (isUrlAlike(hint)) {
        // The hint looks like an url

        // Strip the `http` / `https` protocol
        setResolvedHint(hint.replace(/https?:\/\//, ''));
      } else {
        // Display full hint
        setResolvedHint(hint);
      }
    } else {
      setHintVisible(false);
      setResolvedHint('');
    }
  }, [title, hint]);

  return (
    <div className="d-flex align-items-center my-1 px-1 border rounded">
      <div className="d-flex justify-content-center align-items-center text-muted app-AbstractTouchable-iconContainer">
        {icon}
      </div>
      <div className="flex-fill ms-1">
        <div className="text-body">{title}</div>
        {isHintVisible && (
          <div className="text-muted app-AbstractTouchable-hint">
            {resolvedHint}
          </div>
        )}
      </div>
    </div>
  );
};

export default AbstractTouchable;
