import {useTranslation} from 'react-i18next';


import AbstractTouchable from './AbstractTouchable';


type Props = {
  email: string;
  partyName: string;
  title?: string;
};

const EmailTouchable = ({email, partyName, title}: Props) => {
  const {t} = useTranslation();

  return email.length > 0 ? (
    <AbstractTouchable
      icon={<i className="fas fa-envelope-open-text" />}
      contentLabel={t('contact.email.emailAddress')}
      contentToCopy={email}
      hint={email}
      href={`mailto:${email}`}
      title={title || email}
    />
  ) : null;
};

export default EmailTouchable;
