import axios from 'axios';


import environment from 'src/environment';

import { BaseResponse } from 'src/app/models/response/BaseResponse';


const apiAxios = axios.create({
  baseURL: environment.apiBase,
});


export async function apiGet<T = BaseResponse>(relativeUrl: string, params?: object): Promise<T> {
  console.log(`[GET] ${environment.apiBase}/${relativeUrl} with`, params);

  return apiAxios.get<T>(relativeUrl, { params })
    .then(axiosResponse => axiosResponse.data)
    .then(json => {
      console.log('response', json);
      return json;
    });
}

export async function apiPost<T = BaseResponse>(relativeUrl: string, data?: object): Promise<T> {
  console.log(`[POST] ${environment.apiBase}/${relativeUrl} with`, data);

  return apiAxios.post<T>(relativeUrl, data)
    .then(axiosResponse => axiosResponse.data)
    .then(json => {
      console.log('response', json);
      return json;
    });
}
