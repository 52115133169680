function PrivacyStatement() {
  return (
    <div className="container pt-2">
      <section id="privacy">
        <h1 className="text-center">資料收集及私隱政策</h1>

        <p>
          「月初有道」包含此網站、手機應用程式（Android 及 iOS
          平台），與其相連之伺服器、及其所提供的服務，下文統稱「本平台」。
        </p>

        <h2>收集資料之用途及聲明</h2>
        <p>
          在用戶使用本平台提供的服務時，本平台會按需要以合法的方式向用戶收集適量的資料，包括但不限於用戶的取向、聯絡方法、或用以識別個人身份的資訊。用戶有權拒絕提供相關資訊；唯當本平台未能從用戶徵得本平台所要求的資訊時，本平台可能無法向用戶提供相關服務。
        </p>

        <h2>資料的分析及推廣</h2>
        <p>
          本平台以 Google Analytics
          統計瀏覽量、評估應用程式之流暢度、分析用戶使用本平台服務時的滿意度之用。本平台可能會將
          Google Analytics
          所提供的不含有用戶的聯絡方法、及不含有識別個人身份的資訊之統計、評估或分析結果，包括數據、報表或圖表，用於公開發佈、推廣本平台之用。
          <br />
          Google Analytics 是由 Google 提供的網絡數據分析服務。
        </p>

        <h2>資料的轉移及存取</h2>
        <p>
          用戶提供予本平台的資料，包括用戶於此網站及手機應用程式所輸入的資料（如有的話），會按實際需要而儲存於用戶的手機內、用戶的網頁瀏覽器內，也有可能被傳輸、並且儲存到本平台的後台伺服器內。
        </p>
        <p>
          本平台當對用戶提供予本平台的個人資料予以保密。只有獲得本平台授權的人士、在處理本平台提供的服務事宜上，才會被允許接觸、查閱、存取用戶向本平台提供的個人資料。
        </p>
        <p>
          倘若未得到用戶的同意，本平台並不會將用戶提供予本平台的個人資料向任何以上沒有提及過的第三方人士或團體披露。
        </p>

        <h2>私隱政策的變更</h2>
        <p>
          當本平台認為有需要時，本平台有權對此私隱政策作出更新、修改、修訂或更改。若本平台決定更新、修改、修訂或更改此私隱政策，本平台將於此網站和/或以其他本平台認為合適的渠道發佈該等變更。用戶能隨時瀏覽本網站以查閱最新修訂的私隱政策。
        </p>
      </section>
    </div>
  );
}

export default PrivacyStatement;
